import React from "react";
// import { useSelector } from 'react-redux';
// import ChatBox from '../ChatBox';


const Footer = () => {

  // const { user: userInfo } = useSelector(state => ({ ...state }));
  return (

    <div className='box u-margin-top-huge' >
      {/* {userInfo && !userInfo.isAdmin && <ChatBox userInfo={userInfo} />} */}

			{/* <h1 className="footer-header">
				GeeksforGeeks: A Computer Science Portal for Geeks
			</h1> */}
			{/* <div className='container'>
				<div className="row">
				<div className="column">
					<div className='heading'>About Us</div>
					<div className='footLink' href="#">Aim</div >
					<div className='footLink' href="#">Vision</div >
					<div className='footLink' href="#">Testimonials</div >
				</div>
				<div className="column">
					<div className="heading">Services</div>
					<div className='footLink' href="#">Writing</div >
					<div className='footLink' href="#">Internships</div >
					<div className='footLink' href="#">Coding</div >
					<div className='footLink' href="#">Teaching</div >
				</div >
				<div className="column">
					<div className="heading">Contact Us</div>
					<div className='footLink' href="#">Uttar Pradesh</div >
					<div className='footLink' href="#">Ahemdabad</div >
					<div className='footLink' href="#">Indore</div >
					<div className='footLink' href="#">Mumbai</div >
				</div >
				<div className="column">
					<div className="heading">Social Media</div>
					<div className='footLink' href="#">
					<i className="fab fa-facebook-f">
						<span style={{ marginLeft: "10px" }}>
						Facebook
						</span>
					</i>
					</div>
					<div className='footLink' href="#">
					<i className="fab fa-instagram">
						<span style={{ marginLeft: "10px" }}>
						Instagram
						</span>
					</i>
					</div >
					<div className='footLink' href="#">
					<i className="fab fa-twitter">
						<span style={{ marginLeft: "10px" }}>
						Twitter
						</span>
					</i>
					</div >
					<div className='footLink' href="#">
					<i className="fab fa-youtube">
						<span style={{ marginLeft: "10px" }}>
						Youtube
						</span>
					</i>
					</div >
				</div >
				</div>
			</div> */}
			 <div className="footer-copyRight">Code,DesignAndCreate &copy; All right reserved</div>{' '}
		</div>

);
};
export default Footer;
